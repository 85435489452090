.service-details {
  padding: 60px 0;
}

.service-details .card {
  margin-bottom: 30px;
  border: none;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: transform 0.3s ease;
}

.service-details .card:hover {
  transform: translateY(-10px);
}

.service-details .card-img {
  height: 300px;
  overflow: hidden;
}

.service-details .card-img {
  height: 300px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}
.service-details .card-img img {
  width: 100%;
  height: 100%;
  object-fit: contain; /* Mantém a imagem inteira visível */
  border-radius: 5px;
  background-color: #f8f9fa; /* Cor de fundo para imagens menores */
}

.service-details .card-body {
  padding: 20px;
  background: #fff;
}

.service-details .card-title a {
  font-size: 1.5em;
  font-weight: bold;
  color: #333;
  text-decoration: none;
}

.service-details .card-title a:hover {
  color: #007bff;
}

.service-details .card-text {
  margin-bottom: 20px;
  color: #666;
}

.service-details .read-more a {
  font-size: 1em;
  color: #007bff;
  text-decoration: none;
  font-weight: bold;
}

.service-details .read-more a:hover {
  color: #0056b3;
}
